@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

.shake-animation {
  animation: shake 0.5s ease;
}
.terms {
  font-size: 10px;
  display: block;
}

/* Whatsapp icon  */
.lk1,
.chat-class,
.chat-ui {
  display: block;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hp_sec5 .swiper-container-wrapper {
  margin-bottom: 40px;
}

.popular-city {
  cursor: pointer;
}

.refreshCapatcha {
  cursor: pointer;
}

.error {
  color: red;
  font-size: 14px;
  position: relative;
  top: -15px;
  font-family: sans-serif;
}

.otp-message {
  color: green;
  font-size: 14px;
  position: relative;
  top: -15px;
  font-family: sans-serif;
}

.footer-error {
  color: red;
  font-size: 14px;
  position: relative;
  top: 10px;
  font-family: sans-serif;
}

.upload_cv {
  color: black;
  font-size: 14px;
  position: relative;
  top: -15px;
  font-family: sans-serif;
}

/* Page Not Found Css Start */

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;

  .text-center {
    text-align: center;
    h1,
    h3 {
      margin: 10px;
      cursor: default;

      .fade-in {
        animation: fadeIn 2s ease infinite;
      }
    }

    h1 {
      font-size: 8em;
      transition: font-size 200ms ease-in-out;
      border-bottom: 1px dashed white;

      span#digit1 {
        animation-delay: 200ms;
      }
      span#digit2 {
        animation-delay: 300ms;
      }
      span#digit3 {
        animation-delay: 400ms;
      }
    }

    button {
      border: 1px solid white;
      background: transparent;
      outline: none;
      padding: 10px 20px;
      font-size: 1.1rem;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      transition: background-color 200ms ease-in;
      margin: 20px 0;

      &:hover {
        background-color: white;
        color: #555;
        cursor: pointer;
      }
    }
  }
}

@keyframes colorSlide {
  0% {
    background-color: #152a68;
  }
  25% {
    background-color: royalblue;
  }
  50% {
    background-color: seagreen;
  }
  75% {
    background-color: tomato;
  }
  100% {
    background-color: #152a68;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Page Not Found Css End */

.alert-success {
  text-align: center;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success.error {
  text-align: center;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: red;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.map-img {
  width: 100%;
  height: 350px;
}

.button-submit {
  cursor: pointer;
}

.star-rating {
  margin-bottom: 30px;
}

.popup_call .lts {
  width: 50%;
  margin: 0px;
  background: url(/public/images/pop_pic1.jpg) no-repeat center;
  background-size: cover;
}

.custom-style-for-faq-section {
  margin-bottom: 0px !important;
  padding-top: 40px !important;
  padding-bottom: 80px !important;
  background-color: #ffffff !important;
}

.author-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.location-search-box {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 10px;
  background-color: #ffffff;
}

.ic {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px; /* Adjust the width as needed */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px; /* Adjust padding as needed */
}

.location-search-box select {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 100%;
  border: none;
  width: 100%;
  outline: none;
  padding: 10px; /* Adjust padding as needed */
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-label {
  margin-right: 20px; /* Adjust margin as needed */
}

.checkbox-container {
  display: flex;
  flex-direction: column;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  margin-top: 5px; /* Adjust margin as needed */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 5px; /* Adjust margin as needed */
}

.custom-datepicker {
  width: 280px !important;
  height: 35px !important;
}

.sorts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  padding: 0px 20px 20px 0px;
}

.sorts span {
  margin-right: 10px;
  color: #6b6b6b;
  text-transform: uppercase;
}

.sorts select {
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
}

/* Style the dropdown arrow */
.sorts select::-ms-expand {
  display: none;
}

.sorts select option {
  background-color: #fff;
}

.topbanner .containers {
  width: 100%;
  height: 100%;
  text-align: center !important;
  margin: 20px;
}

.topbanner .txs {
  font-size: 40px;
  max-width: 500px;
  font-weight: 700;
  line-height: 1;
  color: black;
}

.topbanner h1.txs {
  text-align: center;
  position: static !important;
  font-size: 40px;
  max-width: 100%;
  font-weight: 700;
  line-height: 1;
  color: black;
}
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #333;
  z-index: 999;
}

.titles {
  font-size: 26px !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  margin-bottom: 10px !important;
}

.bxs {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.ic {
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  /* height: 56px !important; */
  /* width: 56px !important; */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.selects {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 56px;
  border: none;
  width: 100%;
  outline: none;
}
.bx-ph {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 90px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.ccs {
  position: absolute;
  top: 6px;
  left: 50px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  border-right: 1px solid #d6d6d6;
  padding: 12px 10px 12px 0px;
}

.captchas {
  display: flex;
}

.bx-captchas {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 10px;
  margin-bottom: 20px;
  width: 100%;
  padding-left: 0px;
}

.bx-captchas-img {
  padding: 0px 20px;
  margin-top: -12px;
}

.bx-captchas-lbl {
  width: 100%;
  height: 56px;
  background-image: url(/public/images/captch-bg.jpg);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
}

.agrees {
  position: relative;
  padding-left: 50px;
  margin: 0px auto 30px 0px;
  max-width: 600px;
}
.ls {
  position: absolute;
  top: 0px;
  left: 0px;
}
.rs {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #3b3b3b;
}

.otp-input {
  border: 1px solid #d9d9d9 !important;
  height: 56px !important;
}

/* #24 april 2024  */

.blog-text {
  clear: both;
}

.dv .tt1 {
  margin-left: auto !important;
}
.dv .tt2 {
  margin-left: auto !important;
}

.swiper-wrapper div {
  margin-left: 0 !important;
}

.pgProduct .sec12 .item .pc img {
  width: auto !important;
  margin: auto;
}

.pgBlog.pgLocDr .sec1 .lt .pc {
  width: auto !important;
  margin: auto;
  margin-bottom: 30px;
}
.popup_call .rt .captcha .lbl {
  background-image: url(/public/images/captch-bg.jpg);
}
@media (max-width: 1280px) {
  .pgProduct .sec12 .item .pc img {
    width: 100% !important;
  }
  .pgProduct .sec12 .item .t1 {
    font-size: 20px;
  }
}
@media (max-width: 980px) {
  .pgProduct .sec11 .lt .dv {
    padding-left: 56px;
  }
}

.bxz {
  border: 1px solid #d9d9d9 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  padding-left: 50px !important;
  padding-right: 10px !important;
  margin-bottom: 20px !important;
}

.inpz {
  height: 56px;
  padding-top: 12px;
}

/* 03-may-2024 */

.inpz .react-datepicker-wrapper {
  width: 100% !important;
}
.popup_call .rt button.react-datepicker__navigation {
  width: 10% !important;
  height: 30px;
}
.react-datepicker__day--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #7d65ac !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #7d65ac89 !important;
}

.bxz {
  position: relative;
  overflow: visible !important;
}
.bxz::before {
  content: "";
  position: absolute;
  left: 13px;
  top: 10px;
  background-image: url(/public/images/date-picker.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 34px;
  width: 34px;
}
.bxs .ic {
  display: none !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:nth-child(n + 1):nth-child(
    -n + 20
  ) {
  display: none !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--disabled:nth-child(n + 41):nth-child(
    -n + 48
  ) {
  display: none !important;
}

.react-datepicker__time-container {
  width: 100px !important;
}

.name-img1 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bxs-otp {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 10px;
  margin-bottom: 20px;
}
.failed-message {
  color: red;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  height: 58px;
  line-height: 40px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: auto !important;
}
.bx-1 {
  overflow: visible !important;
}
.input-1 {
  border-radius: 28px !important;
}

.otp-button {
  display: flex;
}

.r1 {
  margin: 8px !important;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.otp-input {
  height: 56px !important;
  border: none !important;
}
.timer-text {
  margin-top: 5px !important;
  font-size: 14px !important;
  position: relative !important;
  font-family: sans-serif !important;
}

.ot1 {
  margin-top: 18px !important;
}

.otp-message1 {
  color: green;
  font-size: 14px;
  font-family: sans-serif;
}
.failed-message1 {
  color: red;
}

.pgAbout.about-us .sec3 {
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .assessment_form{
    width:90% !important;
  } 

  .name-img1{
    height:44px;
    width:44px;
  }
  .landing .sec1 input[type=text]{
    height:44px;
  }
  .ccs{
    top:0px;
    font-size:16px;
  }
  .selects{
    height:44px;
  }
  .checkmark{
    height:30px;
    width:30px;
  }
  .rs{
    font-size:12px;
    line-height:1.2;
  } 
  .pgProduct .lessmargin {
    padding: 0px 20px !important;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .popup_call .rt .bx select {
    background: transparent;
  }
  @media (max-width: 767px) {
    .pgAbout .sec2 .item img {
      height: 100% !important;
    }
  }
}

.ftr_mobile a img {
  width: 40px;
}

.job-title-color {
  color: #7d65ac !important;
}

.new-sec1 {
  display: flex !important;
  align-items: center !important;
  max-width: unset !important;
}

.banner-img {
  width: 100% !important;
  padding-left: 20px !important;
}

.book-button {
  width: 100%;
  border-radius: 26px;
  background: #7d65ac;
  font-size: 16px;
  border: none;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #ffffff;
}

.new-ct {
  display: flex !important;
  flex-direction: column !important;
}

.botbar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: #ffffff;
  z-index: 99999;
  padding: 20px;
  font-weight: 500;
  line-height: 1.2;
}
.botbar .bb_lt {
  float: left;
}
.botbar .bb_lt .bb_t1 {
  font-weight: 600;
  margin-bottom: 6px;
}
.botbar .bb_lt .bb_t2 {
  font-size: 14px;
}
.botbar .bb_rt {
  float: right;
}
.botbar .bb_rt .bb_lk {
  height: 40px;
  display: block;
  border-radius: 20.5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 30px 10px 30px;
  background: #7d65ac;
  margin-top: 3px;
  transition: 0.5s;
}
.botbar .bb_rt .bb_lk:hover {
  background-color: #000000;
}

@media (max-width: 767px) {
  .botbar .bb_lt {
    display: none;
  }
  .botbar .bb_rt {
    float: none;
    width: 100%;
  }
  .botbar .bb_rt a {
    text-align: center;
    width: 100%;
    max-width: 300px;
    margin: 0px auto;
  }
}

/* .ic-callback{
    align-items: center !important;
    display: flex !important;
    height: 48px !important;
    justify-content: center !important;
    left: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 48px !important;
} */

.play {
  cursor: pointer;
}

.landing .title h1.assesmemt-title {
  margin-bottom: 30px;
  font-size: 32px;
}

.assessment_form {
  width: 80%;
  margin: 0 auto;
}
.landing .title h2.assesmemt-title {
  margin-bottom: 10px !important;
  font-size: 22px !important;
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.smo-share-btn {
  padding-right: 10px !important;
}

/* styles.css */
.img-desktop {
  display: block !important;
}

.img-mobile {
  display: none !important;
}

@media (max-width: 767px) {
  .img-desktop {
    display: none !important;
  }

  .img-mobile {
    display: block !important;
  }
}
