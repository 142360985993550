/* General container styling */


.sitemaphtml-cities-list {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.sitemaphtml-cities-list h2 {
  margin: 20px 0;
  font-size: 22px;
}

.sitemaphtml-main-heading {
  font-weight: 600;
  text-align: center;
  font-size: 32px;
}

/* Styling for the city container */
.sitemaphtml-cities-container {
  list-style-type: none;
  padding: 0;
}

/* Each city item */
.sitemaphtml-city-item {
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 20px;
}

/* City title */
.sitemaphtml-city-title {
  font-size: 24px;
  color: #333;
  margin: 0;
}

/* Container for clinics */
.sitemaphtml-clinics-container {
  margin-top: 10px;
}

/* The list of clinics, using flexbox */
.sitemaphtml-clinic-list {
  display: flex;
  flex-wrap: wrap; /* Allow clinics to wrap onto multiple lines */
  padding: 0;
  list-style-type: none;
  margin: 0;
}

/* Each clinic item */
.sitemaphtml-clinic-item {
  width: 300px; /* Adjust clinic box size */
  margin: 10px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Clinic name */
.sitemaphtml-clinic-item h3 {
  font-size: 20px;
  margin: 0;
}

/* Clinic address or description */
.sitemaphtml-clinic-item p {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

/* Styling for the "No clinics found" message */
.sitemaphtml-clinics-container p {
  color: #888;
  font-style: italic;
}

/* Loader message */
.sitemaphtml-p {
  color: #333;
  font-size: 16px;
}

/* General container styling */
.sitemaphtml-products-and-treatments {
  font-family: Arial, sans-serif;
  padding: 20px;
}

/* Styling for the products and treatments container */
.sitemaphtml-items-container {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

/* Each item (product or treatment) */
.sitemaphtml-item {
  width: 300px; /* Adjust item box size */
  margin: 10px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Item name */
.sitemaphtml-item span {
  font-size: 20px;
  margin: 0;
  display: block;
}

/* Styling for the "No items found" message */
.sitemaphtml-items-container p {
  color: #888;
  font-style: italic;
}
